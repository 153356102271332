import { IFilterClassRequest, IGetClassResponse } from '@/interfaces/classroom/classroom.interface';
import { IOption } from '@/interfaces/common/common.interface';
import {
	ICourseCrossSellAnchor,
	ICoursePracticalityContent,
	ICoursePracticalityFaq,
	ICourseProgramBucket,
	ICourseProgramRoadmap,
	ICourseStyle,
	ICourseTestimonialContent,
	IFormAssessmentQuiz,
	IFormQuestionTrueFalse,
	IGetCoursesResponse,
} from '@/interfaces/courses/courses.interface';
import { IGetChannelsResponse } from '@/interfaces/settings/channels/channels.interface';
import { IDiscountOrderBy } from '@/interfaces/settings/discount/discount.interface';
import { IPrintedCertificateOrderBy } from '@/interfaces/settings/printedCertificate/printedCertificate';
import { IGetRoleByIdResponse } from '@/interfaces/settings/roles/roles.interface';
import { IGetTranslationDataTableResponse } from '@/interfaces/translation/translation.interface';
import { IGetCommunitiesResponse } from '@/interfaces/users/communities/communities.interface';
import { IFilterUsersRequest, IGetUsersResponse } from '@/interfaces/users/user.interface';
import {
	IFormQuestionDragNDrop,
	IFormQuestionQuiz,
} from './../interfaces/courses/courses.interface';

export const API_URL = process.env.REACT_APP_API_BASE_URL;
/* ---------------------------------- TABLE --------------------------------- */
export enum KEY_TOOLS_TABLE {
	TOOLS = 'tools',
	INFO = 'info',
}

export enum ACTIONS_TYPE {
	EDIT = 'edit',
	DELETE = 'delete',
	ADD = 'add',
	UPDATE_STATUS = 'update-status',
	MANAGE_PAYMENT = 'manage-payment',
	DUPLICATE = 'duplicate',
}
export enum ID_TABLE_GEOGRAPHY {
	REGION = 'REGION',
	COUNTRY_MARKET = 'COUNTRY/MARKET',
	WORKPLACE = 'WORKPLACE',
}

export enum ID_TABLE_THEMATIC {
	THEMATIC = 'THEMATIC',
}

export enum ID_TABLE_PRINTED_CERTIFICATE {
	PRINTED_CERTIFICATE = 'CERTIFICATE',
}

export enum ID_TABLE_DISCOUNT {
	DISCOUNT = 'DISCOUNT',
}

export enum ID_TABLE_ROLES {
	ROLE = 'ROLE',
}

export enum ID_TABLE_CHANNELS {
	CHANNEL = 'CHANNEL',
}

export enum ID_TABLE_COURSES {
	COURSE = 'COURSE',
	COURSE_ONLINE = 'COURSE_ONLINE',
	COURSE_PDF = 'COURSE_PDF',
	COURSE_VIDEO = 'COURSE_VIDEO',
	COURSE_IN_CLASS = 'COURSE_IN_CLASS',
	PRODUCT_SHEET = 'PRODUCT_SHEET',
	COURSE_NEWS = 'COURSE_NEWS',
	GAME_QUIZ = 'GAME_QUIZ',
	GAME_DRAG_N_DROP = 'GAME_DRAG_N_DROP',
	GAME_TRUE_FALSE = 'GAME_TRUE_FALSE',
	SELF_ASSESSMENT_QUIZ = 'SELF_ASSESSMENT_QUIZ',
	TRAINING_RESOURCES = 'TRAINING_RESOURCES',
}

export enum ID_TABLE_ARBORESCENCE {
	MAIN = 'MAIN',
	LEVEL_1 = 'LEVEL 1',
	LEVEL_2 = 'LEVEL 2',
	LEVEL_3 = 'LEVEL 3',
	LEVEL_4 = 'LEVEL 4',
}

export enum ID_TABLE_USERS {
	USER = 'USER',
	USERS = 'USERS',
}

export enum ID_TABLE_COMMUNITIES {
	COMMUNITIES = 'COMMUNITIES',
}

export enum ID_TABLE_STATISTICS {
	TOP_10 = 'TOP 10',
	LAST_LAUNCHES = 'LAST LAUNCHES',
}
export enum ID_TABLE_SESSION {
	CLASSROOM = 'CLASSROOM',
	SESSION = 'SESSION',
}

export enum ID_TABLE_TRANSLATIONS {
	TRANSLATIONS = 'TRANSLATIONS',
	SESSION = 'SESSION',
	TRANSLATION_ONLINE = 'TRANSLATION_ONLINE',
	TRANSLATION_PDF = 'TRANSLATION_PDF',
	TRANSLATION_VIDEO = 'TRANSLATION_VIDEO',
	TRANSLATION_IN_CLASS = 'TRANSLATION_IN_CLASS',
}
/* ---------------------------------- FILE ---------------------------------- */
export enum UPLOAD_FILE_STATUS {
	NO_FILE_CHOSEN = 'No file chosen',
	FILE_UPLOADING = 'File is uploading',
	FILE_UPLOAD_SUCCESS = 'File upload succeeded',
	FILE_UPLOAD_FAILED = 'File upload failed',
	UNSUPPORTED_FILE_FORMAT = 'Unsupported file format',
	OVER_LIMIT_FILE = 'File size exceeds limit',
}

export enum FILE_TYPES_EXT {
	ZIP = '.zip',
	MP4 = '.mp4',
	PDF = '.pdf',
	XLSM = '.xlsm',
	XLSX = '.xlsx',
	JPEG = '.jpeg',
	JPG = '.jpg',
	PNG = '.png',
	WEBP = '.webp',
}

/* --------------------------------- OTHERS --------------------------------- */

export const DELAY_TIME_HIDE_MODAL = 500;

export const DELAY_TIME_ROUTE_TO_404 = 2000;

export enum ADMINISTRATION_ACCESS_TYPE {
	NoAccess = 0,
	FrontOfficeUser = 1,
	InternationalAdmin = 2,
	RegionalAdmin = 3,
	LocalAdmin = 4,
}

export const SEARCH_ALL_VALUE = ['00000000-0000-0000-0000-000000000000'];

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export enum OPEN_COUNTRIES_MODAL_TYPE {
	BACK_OFFICE = 'BACK_OFFICE',
	FRONT_OFFICE = 'FRONT_OFFICE',
}
export enum COURSE_STYLE {
	None = 0,
	CourseWithFindOutMore = 1,
	LongCourse = 2,
	ShortCourse = 3,
}
export const courseStyles: ICourseStyle[] = [
	{ codeStyle: COURSE_STYLE.CourseWithFindOutMore, label: 'Style 1 - Course with find out more' },
	{ codeStyle: COURSE_STYLE.LongCourse, label: 'Style 2 - Long course' },
	{ codeStyle: COURSE_STYLE.ShortCourse, label: 'Style 3 - Short course' },
];
/* --------------------------------- ENTITY --------------------------------- */
export enum ENTITY_TYPES {
	DBJ = 1,
	IoD = 2,
}

/* --------------------------------- COURSE --------------------------------- */
export enum COURSES_STATUS {
	ComingSoon = 1,
	Published = 2,
	Archived = 3,
	Deleted = 4,
}

export enum COURSES_TYPES {
	OnlineCourse = 1,
	InClassCourse = 2,
	PDF = 3,
	Video = 4,
	ProductSheet = 5,
	GameDragNDrop = 6,
	GameQuiz = 7,
	GameTrueFalse = 8,
	News = 9,
	SelfAssessmentQuiz = 10,
	TrainingResources = 11,
}

export enum COURSE_OPEN_COUNTRIES {
	BACK_OFFICE = 'BACK_OFFICE',
	FRONT_OFFICE = 'FRONT_OFFICE',
}

export enum COURSE_TYPES_STRING {
	InClassCourse = 'IN-CLASS COURSE',
	OnlineCourse = 'ONLINE COURSE',
	PDF = 'PDF',
	ProductSheet = 'PRODUCT SHEETS',
	Video = 'VIDEO',
	News = 'NEWS',
	GameQuiz = 'GAME - QUIZ',
	GameDragNDrop = 'GAME - DRAG AND DROP',
	GameTrueFalse = 'GAME - TRUE FALSE',
	SelfAssessmentQuiz = 'SELF-ASSESSMENT QUIZ',
	TrainingResources = 'TRAINING RESOURCES',
}

export const EMPTY_PRE_REQUISITE = {
	label: '( None Pre-Requisite Course )',
	value: EMPTY_GUID,
};

export const EMPTY_NEXT_COURSE = {
	label: '( None Next Course )',
	value: null,
};

export enum COURSE_JOB_STATUS {
	Unknown = 0,
	Pending = 1,
	Failed = 2,
	Successful = 3,
}

export const DEFAULT_IOD_COURSE_POINT = 0;
export const NUMBER_TRANSLATED_LANGUAGES_NOT_TYPE_COURSE = 0;

/* ---------------------------------- ROLE ---------------------------------- */

export enum ROLE_TYPES {
	Standard = 1,
	Manager = 2,
}

export const ROLE_TYPES_NAME = {
	Manager: 'Manager',
	Standard: 'Standard',
};
export enum RULE_TYPES {
	None = 1,
	AllUsers = 2,
	Communities = 3,
	BrandsAndRoles = 4,
	AnonymousUsers = 5,
	Workplaces = 6,
}

export enum BRAND_CODE {
	DBJ = 'DBJ',
	DBFM = 'DBFM',
	IoD = 'IoD',
	ORI = 'ORI',
}

/* ------------------------------- PAGINATION ------------------------------- */
export const PAGE_SIZE = 10;

/* ---------------------------------- DATE ---------------------------------- */
export const FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME_LOCAL = 'YYYY-MM-DDTHH:mm';
export const INFINITE_DATE = 1e15;
/* ------------------------------- RATIO IMAGE ------------------------------ */
export enum RATIO_IMAGE {
	Level1Picture = 1.9,
	PictureBanner = 2.9,
	NewsContent = 1.6,
	QuizContent = 1.6,
	MainPicture = 0.76,
	AdditionPicture = 1,
	VideoPreview = 2,
	RoadMapPicture = 1.48,
	IconFAQ = 1,
	AvatarTestimonials = 1,
	PictureBannerStyle1 = 2.9,
	MainPictureStyle1 = 0.76,
	PictureBannerLongCourse = 2,
	MainPictureLongCourse = 1.6,
	PictureBannerShortCourse = 1,
	MainPictureShortCourse = 1,
	CertificateBanner = 1.6,
}

/* ------------------------------- ORDER TABLE ------------------------------ */
export const ORDER_TABLE_ROLE_REQUEST: (keyof IGetRoleByIdResponse)[] = [
	'roleTypeName',
	'roleName',
];
export const ORDER_TABLE_COURSE_REQUEST: (keyof IGetCoursesResponse)[] = [
	'courseTypeName',
	'courseStatusName',
	'idTitle',
];
export const ORDER_TABLE_CHANNEL_REQUEST: (keyof IGetChannelsResponse)[] = [
	'channelTypeName',
	'channelName',
];
export const ORDER_TABLE_USER_REQUEST: (keyof IGetUsersResponse)[] = [
	'sentWelcomeEmail',
	'email',
	'fullName',
	'accountStatus',
];
export const ORDER_TABLE_COMMUNITY_REQUEST: (keyof IGetCommunitiesResponse)[] = [
	'communityStatusName',
	'accountCount',
	'communityName',
];
export const ORDER_TABLE_CLASSROOM_REQUEST: (keyof IGetClassResponse)[] = [
	'titleId',
	'statusTitle',
];
export const ORDER_TABLE_TRANSLATION_REQUEST: (keyof IGetTranslationDataTableResponse)[] = [
	'titleId',
	'validatedNumber',
	'translatedNumber',
];

/* --------------------------------- OPTIONS -------------------------------- */
export enum BOOLEAN_FILTER {
	all = '00000000-0000-0000-0000-000000000000',
	true = '1',
	false = '2',
}

export const BOOLEAN_OPTIONS: IOption[] = [
	{
		label: 'YES',
		value: BOOLEAN_FILTER.true,
	},
	{
		label: 'NO',
		value: BOOLEAN_FILTER.false,
	},
];

/* ----------------------------- ACCOUNT STATUS ----------------------------- */
export enum ACCOUNTS_STATUS {
	All = '00000000-0000-0000-0000-000000000000',
	Deactivated = '1',
	Activated = '2',
}

export const ACCOUNT_STATUS_OPTIONS: IOption[] = [
	{
		label: 'ACTIVATED',
		value: ACCOUNTS_STATUS.Activated,
	},
	{
		label: 'NOT ACTIVATED',
		value: ACCOUNTS_STATUS.Deactivated,
	},
];

/* ---------------------------------- XLSM ---------------------------------- */
export const HEADER_XLSM = 'Headers';
export const TEMPLATE_FILE_NAME = 'DeBeersEducation-ImportAccounts';

/* ------------------------------ REPORT STATUS ----------------------------- */
export const enum REPORT_STATUS {
	Error = 0,
	Warning = 1,
	Success = 2,
}

export const COLORS_REPORT_TABLE = {
	backgroundError: '#ff3c3c',
	backgroundSuccess: '#60ff18',
	backgroundWarning: '#ffbb30',
};

export const REGEX_PASSWORD =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{15,30}$/;

export const REGEX_COURSE_TITLE_ID = /^[a-zA-Z0-9-_]+$/;

/* ----------------------------- DEFAULT FILTER ----------------------------- */
export const DEFAULT_USER_FILTER: IFilterUsersRequest = {
	accountStatuses: [ACCOUNTS_STATUS.All],
	backOfficeAdministrationAccessIds: SEARCH_ALL_VALUE,
	brandIds: SEARCH_ALL_VALUE,
	countryIds: SEARCH_ALL_VALUE,
	frontOfficeRoleIds: SEARCH_ALL_VALUE,
	hasAccessToFrontOffice: [BOOLEAN_FILTER.true],
	regionIds: SEARCH_ALL_VALUE,
	sentWelcomeEmail: [BOOLEAN_FILTER.all],
	workplaceIds: SEARCH_ALL_VALUE,
	textSearch: '',
	communityIds: SEARCH_ALL_VALUE,
};

/* ----------------------------- PAYMENT SETTING ---------------------------- */
export enum PAYMENT_ITEM_TYPE {
	Unknown = 0,
	Course = 1,
	Class = 2,
	PrintedCertificate = 3,
}

export enum PAYMENT_TYPES {
	B2C = 1,
	B2B = 2,
}

export enum PAYMENT_RULE_TYPES {
	AllUserRegisteredOnly = 1,
	AllUserOpenedForAnonymous = 2,
	BrandsAndRoles = 3,
	Workplaces = 4,
}

/* ------------------------------- TRANSLATION ------------------------------ */
export enum TRANSLATION_DATA {
	Course = 1,
	Country = 2,
	Thematic = 3,
	Level = 4,
}

export enum TRANSLATION_LEVEL {
	All = '0',
	Level1 = '1',
	Level2 = '2',
	Level3 = '3',
}

export enum TRANSLATION_STATUS {
	All = 0,
	NotTranslated = 1,
	NotValidated = 2,
	Validated = 3,
}
/* ----------------------------- LIST LANGUAGES ----------------------------- */
export enum LIST_LANGUAGES_ENUM {
	EN_GB = 'en-GB',
	FR = 'fr',
}
export const LIST_LANGUAGES: IOption[] = [
	{
		label: 'English',
		value: LIST_LANGUAGES_ENUM.EN_GB,
	},
	{
		label: 'French',
		value: LIST_LANGUAGES_ENUM.FR,
	},
];

/* --------------------------- TRAINING FOLLOW UP --------------------------- */
export enum COURSE_COMPLETION_STATUS {
	Unknown,
	NotAttempted,
	Completed,
	Incomplete,
}

/* --------------------------------- SESSION -------------------------------- */
export enum SESSION_STATUS {
	WAITING_FOR_ENROLLMENT = 10,
	ENROLLMENT = 20,
	WAITING_FOR_START = 30,
	STARTED = 40,
	ENDED = 50,
	CANCELED = 60,
}

export enum SESSION_STATUS_NAME {
	WAITING_FOR_ENROLLMENT = 'WaitingForEnrollment',
	ENROLLMENT = 'Enrollment',
	WAITING_FOR_START = 'WaitingForStart',
	STARTED = 'Started',
	ENDED = 'Ended',
	CANCELED = 'Canceled',
}

export enum PARTICIPANT_STATUS {
	WAITING_FOR_VALIDATION = 10,
	CONFIRMED = 20,
	CHECKED_IN = 30,
	CANCELED = 40,
	DECLINED = 50,
}

/* ------------------------------- STATISTICS ------------------------------- */
export enum STATISTICS_DISPLAY {
	YEAR = 0,
	MONTH = 1,
	WEEK = 2,
}

export const STATISTICS_DISPLAY_OPTIONS = [
	{ label: 'YEAR', value: STATISTICS_DISPLAY.YEAR },
	{ label: 'MONTH', value: STATISTICS_DISPLAY.MONTH },
	{ label: 'WEEK', value: STATISTICS_DISPLAY.WEEK },
];
//NOTE PRODUCT SHEET
export enum STATISTICS_COURSES_TYPES {
	ALL = 0,
	ONLINE_COURSE = 1,
	INCLASS_COURSE = 2,
	PDF = 3,
	VIDEO = 4,
}

export const STATISTICS_COURSES_TYPES_OPTIONS = [
	{ label: 'ALL', value: STATISTICS_COURSES_TYPES.ALL },
	{ label: 'ONLINE COURSE', value: STATISTICS_COURSES_TYPES.ONLINE_COURSE },
	{ label: 'INCLASS COURSE', value: STATISTICS_COURSES_TYPES.INCLASS_COURSE },
	{ label: 'PDF', value: STATISTICS_COURSES_TYPES.PDF },
	{ label: 'VIDEO', value: STATISTICS_COURSES_TYPES.VIDEO },
];
export enum STATISTICS_TABLE_HEADER {
	TITLE = 'idTitle',
	TYPE = 'type',
	CLICK = 'click',
}
/* -------------------------------- DISCOUNT -------------------------------- */
export const ORDER_TABLE_DISCOUNT_REQUEST: (keyof IDiscountOrderBy)[] = [
	'discountId',
	'courseId',
	'limitation',
	'status',
];

export enum DISCOUNT_TYPE_CODE {
	FIXED = 1,
	PERCENTAGE = 2,
}
export enum DISCOUNT_TYPE_CODE_NAME {
	FIXED = 'Fixed',
	PERCENTAGE = 'Percentage',
}
export enum DISCOUNT_LIMITATION_TYPE_CODE {
	SINGLE = 1,
	UNLIMITED = 2,
}
export enum DISCOUNT_LIMITATION_TYPE_CODE_NAME {
	SINGLE = 'Single',
	UNLIMITED = 'Unlimited',
}
export enum DISCOUNT_STATUS_CODE_NAME {
	COMING_SOON = 'COMING SOON',
	PUBLISHED = 'PUBLISHED',
	ARCHIVED = 'ARCHIVED',
	ALL = 'ALL',
}
export enum DISCOUNT_STATUS_CODE {
	COMING_SOON = 0,
	PUBLISHED = 1,
	ARCHIVED = 2,
	ALL = 3,
}
export const DISCOUNT_STATUS_CODE_OPTIONS = [
	{ label: 'ARCHIVED', value: DISCOUNT_STATUS_CODE.ARCHIVED },
	{ label: 'COMING SOON', value: DISCOUNT_STATUS_CODE.COMING_SOON },
	{ label: 'PUBLISHED', value: DISCOUNT_STATUS_CODE.PUBLISHED },
];

export enum DISCOUNT_TARGET_STATUS_CODE {
	ALL = 0,
	ALL_ONLINE_COURSES = 1,
	ALL_IN_CLASS_COURSES = 2,
	SINGLE_COURSE = 3,
}
export enum DISCOUNT_TARGET_STATUS_CODE_NAME {
	ALL = 'ALL COURSES',
	ALL_ONLINE_COURSES = 'ALL ONLINE COURSES',
	ALL_IN_CLASS_COURSES = 'ALL IN-CLASS COURSES',
	SINGLE_COURSE = 'SINGLE COURSE',
}
export const DISCOUNT_COURSES_OPTIONS = [
	{ label: DISCOUNT_TARGET_STATUS_CODE_NAME.ALL, value: DISCOUNT_TARGET_STATUS_CODE.ALL },
	{
		label: DISCOUNT_TARGET_STATUS_CODE_NAME.ALL_IN_CLASS_COURSES,
		value: DISCOUNT_TARGET_STATUS_CODE.ALL_IN_CLASS_COURSES,
	},
	{
		label: DISCOUNT_TARGET_STATUS_CODE_NAME.ALL_ONLINE_COURSES,
		value: DISCOUNT_TARGET_STATUS_CODE.ALL_ONLINE_COURSES,
	},
];
export const VALUE_ALL_SESSIONS = '0';
export const DISCOUNT_SESSION_OPTIONS = [{ label: 'All sessions', value: VALUE_ALL_SESSIONS }];

/* --------------------------- PRINTED CERTIFICATE -------------------------- */
export enum PRINTED_CERTIFICATE_STATUS {
	ALLOWED = 0,
	NOT_ALLOWED = 1,
	ALL = 2,
}
export enum PRINTED_CERTIFICATE_STATUS_NAME {
	ALLOWED = 'ALLOWED',
	NOT_ALLOWED = 'NOT ALLOWED',
}
export const PRINTED_CERTIFICATE_STATUS_OPTIONS = [
	{ label: 'ALLOWED', value: PRINTED_CERTIFICATE_STATUS.ALLOWED },
	{ label: 'NOT ALLOWED', value: PRINTED_CERTIFICATE_STATUS.NOT_ALLOWED },
];
export const ORDER_TABLE_PRINTED_REQUEST: (keyof IPrintedCertificateOrderBy)[] = [
	'courseId',
	'courseTypeName',
	'paymentStatus',
];

/* ------------------------------- NAVIGATION ------------------------------- */
export enum MENU_NAME {
	USERS = 'USERS',
	SETTINGS = 'SETTINGS',
	MANAGE_FRONT_OFFICE = 'MANAGE FRONT OFFICE',
	COURSES = 'COURSES',
	CLASSROOM = 'CLASSROOM',
	TRANSLATION = 'TRANSLATION',
	STATISTICS = 'STATISTICS',
}

export enum TAB_SETTINGS_NAME {
	GEOGRAPHY = 'GEOGRAPHY',
	ROLES = 'ROLES',
	CHANNELS = 'CHANNELS',
	THEMATICS = 'THEMATICS',
	PRINTED_CERTIFICATE = 'PRINTED CERTIFICATE',
	DISCOUNT = 'DISCOUNT',
}

export enum TAB_MANAGE_FRONT_OFFICE_NAME {
	MANAGE_ARBORESCENCE = 'MANAGE ARBORESCENCE',
	CONTENT_LOCATION = 'CONTENT LOCATION',
}

export enum TAB_USERS_NAME {
	ACCOUNTS = 'ACCOUNTS',
	COMMUNITIES = 'COMMUNITIES',
}

export enum TAB_STATISTICS_NAME {
	KPIS = 'KPIS',
	DATABASE = 'DATABASE',
}

export enum STATISTICS_JOB_STATUS {
	PROCESSING = 1,
	ERROR = 2,
	COMPLETED = 3,
}

export const DOMAIN_ENTITIES = {
	IOD: 'diamondeducation',
	DBJ: 'retailacademy',
};

export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/* ----------------------------- DEFAULT FILTER ----------------------------- */
export const DEFAULT_CLASS_FILTER: IFilterClassRequest = {
	coursesId: SEARCH_ALL_VALUE,
	statusesId: SEARCH_ALL_VALUE,
	countriesId: SEARCH_ALL_VALUE,
	textSearch: '',
};

export const MAX_QUESTIONS: number = 10;
export const DEFAULT_QUESTIONS_QUIZ: IFormQuestionQuiz = {
	questionText: '',
	answer1: '',
	answer2: '',
	answer3: '',
	answer4: '',
	answer5: '',
	answer6: '',
	correctAnswer: '',
	ans1Correct: false,
	ans2Correct: false,
	ans3Correct: false,
	ans4Correct: false,
	ans5Correct: false,
	ans6Correct: false,
	debriefText: '',
	picture: '',
};
export const DEFAULT_QUESTIONS_TRUE_FALSE: IFormQuestionTrueFalse = {
	questionText: '',
	correctAnswer: '',
	debriefText: '',
	picture: '',
	isCorrect: false,
};

export const enum OPTION_TYPES {
	TEXT = 1,
	PICTURE = 2,
}

export const enum OPTION_VALUES {
	OPTION_A = 1,
	OPTION_B = 2,
	OPTION_C = 3,
}

export const DEFAULT_QUESTIONS_DRAG_N_DROP: IFormQuestionDragNDrop = {
	questionText: 'Drag the elements on the right to its corresponding words.',
	answer1: '',
	answer2: '',
	answer3: '',
	correctAnswer: '',
	debriefText: '',
	optionA: '',
	optionB: '',
	optionC: '',
	ans1Option: -1,
	ans2Option: -1,
	ans3Option: -1,
	answerType: OPTION_TYPES.TEXT,
	optionType: OPTION_TYPES.PICTURE,
	answerUrl1: '',
	answerUrl2: '',
	answerUrl3: '',
	optionUrlA: '',
	optionUrlB: '',
	optionUrlC: '',
};

export const DEFAULT_COURSE_FAQ: Omit<ICoursePracticalityFaq, 'order'> = {
	questionText: '',
	answerText: '',
};

export const DEFAULT_COURSE_PRACTICALITY_CONTENT: Omit<ICoursePracticalityContent, 'order'> = {
	icon: '',
	text: '',
};

export const DEFAULT_COURSE_TESTIMONIALS: Omit<ICourseTestimonialContent, 'order'> = {
	icon: '',
	name: '',
	subTitle: '',
	visiblePart: '',
	additionalPart: '',
};

export const DEFAULT_COURSE_CROSS_SELL: Omit<ICourseCrossSellAnchor, 'order'> = {
	crossSellCourseId: '',
};

export const DEFAULT_COURSE_ROAD_MAP: Omit<ICourseProgramRoadmap, 'step'> = {
	picture: '',
	text: '',
};

export const DEFAULT_COURSE_PROGRAM_BUCKET: Omit<ICourseProgramBucket, 'order'> = {
	title: '',
	text: '',
};

export const LIST_TYPE_GAME_CONTENT: IOption<number>[] = [
	{
		label: 'Picture',
		value: OPTION_TYPES.PICTURE,
	},
	{
		label: 'Text',
		value: OPTION_TYPES.TEXT,
	},
];

export const LIST_OPTION_CONTENT: IOption<number>[] = [
	{
		label: 'Option A',
		value: OPTION_VALUES.OPTION_A,
	},
	{
		label: 'Option B',
		value: OPTION_VALUES.OPTION_B,
	},
	{
		label: 'Option C',
		value: OPTION_VALUES.OPTION_C,
	},
];

export const ARBORESCENCE_NAME = {
	NEWS: 'News',
	SELF_ASSESSMENT_QUIZ: 'SELF ASSESSMENT QUIZ',
	HOME: 'HOME',
};
export const enum CORRECT_TYPES {
	UNKNOWN = '0',
	CORRECT = '1',
	INCORRECT = '2',
}

/* ----------------------------- ASSESSMENT QUIZ ---------------------------- */
export const MIN_ASSESSMENT_QUESTIONS: number = 1;
export const MAX_ASSESSMENT_QUESTIONS: number = 30;

export const DEFAULT_QUESTIONS_ASSESSMENT_QUIZ: IFormAssessmentQuiz = {
	questionText: '',
	answer1: '',
	answer2: '',
	answer3: '',
	answer4: '',
	answer5: '',
	answer6: '',
	correctAnswer: '',
	ans1Correct: false,
	ans2Correct: false,
	ans3Correct: false,
	ans4Correct: false,
	ans5Correct: false,
	ans6Correct: false,
	thematicId: '',
};

export const MAX_PROGRAM_BUCKETS: number = 11;
export const MAX_PROGRAM_ROADMAP: number = 4;
export const MAX_PROGRAM_PRACTICALITY_CONTENT: number = 6;
export const FIELD_DELETED: string = 'field-deleted';
export const MAX_CROSS_SELL_COURSE: number = 4;

// VERSION API
export const enum VERSION_API {
	V0 = '',
	V1 = 'v1',
	V2 = 'v2',
}

export const DBJ_CHINA_DOMAIN = 'retailacademy.debeers.cn';
