import { IClassStatus, IFilterClassRequest } from '@/interfaces/classroom/classroom.interface';
import { SEARCH_ALL_VALUE } from '@/utils/constants';
import { makeAutoObservable } from 'mobx';

export class ClassroomStore {
	constructor() {
		makeAutoObservable(this);
	}

	classStatuses: IClassStatus[] = [];

	filterOptionState: IFilterClassRequest = {
		coursesId: SEARCH_ALL_VALUE,
		statusesId: SEARCH_ALL_VALUE,
		countriesId: SEARCH_ALL_VALUE,
		textSearch: '',
	};

	async setClassStatuses(classStatuses: IClassStatus[]) {
		return (this.classStatuses = classStatuses);
	}

	async setFilterOptionState(filterOptionState: IFilterClassRequest) {
		return (this.filterOptionState = filterOptionState);
	}
}

export const classroomStore = new ClassroomStore();
