import { useStores } from '@/stores';
import { ADMINISTRATION_ACCESS_TYPE } from '@/utils/constants';

interface IAccess {
	backOfficeAdministrationAccessType?: ADMINISTRATION_ACCESS_TYPE[];
	children: React.ReactElement;
	fallbackComponent?: React.ReactElement;
}

const Access = (props: IAccess) => {
	const { userStore } = useStores();
	const { backOfficeAdministrationAccessType, children, fallbackComponent } = props;
	const currentUserAdminType = userStore.currentUser.backOfficeAdministrationAccessType;
	if (backOfficeAdministrationAccessType?.includes(currentUserAdminType)) {
		return children;
	} else {
		return fallbackComponent ?? null;
	}
};

export default Access;
