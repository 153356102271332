export const LINK_ACTIVE_STYLES = {};

export const DGRAY_COLOR = '#6a747c';
export const DWHITE_COLOR = '#f8f8f8';
export const GRAY_200 = '#e5e7eb';
export const PRIMARY_COLOR = '#c85312';
export const SECONDARY_COLOR = '#041e42';
export const SUCCESS_COLOR = '#4bae4f';
export const ERROR_COLOR = '#ef4444';
export const DISABLED_COLOR = '#f5f5f5';
export const WARNING_COLOR = '#f9f9f9';
