export const ROUTES_NAME = {
	users: '/users',
	accounts: '/accounts',
	communities: '/communities',
	settings: '/settings',
	geography: '/geography',
	actions: '/actions',
	roles: '/roles',
	channels: '/channels',
	thematics: '/thematics',
	printedCertificate: '/printed-certificate',
	discount: '/discount',
	manageFrontOffice: '/manage-front-office',
	courses: '/courses',
	classroom: '/classroom',
	translation: '/translation',
	statistics: '/statistics',
	storybook: '/storybook',
	arborescence: '/arborescence',
	contentLocation: '/content-location',
	login: '/login',
	notFound: '/not-found',
	payment: '/payment',
	selectEntity: '/select-entity',
	oidcLogin: '/oidc',
	loginComplete: '/login-complete',
	report: '/report',
	notAccessSystem: '/not-access-system',
	kpis: '/kpis',
	database: '/database',
	notAccessibleDomain: '/not-accessible',
};
