import { translationStore } from './translation/translation.store';
import { accountStore } from './user/accounts/account.store';
import { arborescenceStore } from './manageFrontOffice/arborescence/arborescence.store';
import { createContext, useContext } from 'react';
import { commonStore } from './common/common.store';
import { coursesStore } from './courses/courses.store';
import { geographyStore } from './setting/geography/geography.store';
import { communitiesStore } from './user/communities/index';
import { userStore } from './user/user.store';
import { classroomStore } from './classroom/classroom.store';

export const stores = {
	userStore,
	geographyStore,
	communitiesStore,
	commonStore,
	coursesStore,
	arborescenceStore,
	accountStore,
	classroomStore,
	translationStore
};

const StoreContext = createContext(stores);

export const StoreProvider = StoreContext.Provider;

export const useStores = () => useContext(StoreContext);
