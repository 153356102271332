import { DGRAY_COLOR } from '@/utils/colors';
import { SVGAttributes } from 'react';

const Spinner = (props: SVGAttributes<SVGSVGElement>) => {
	const { width, height, className, fill } = props;
	const svgProps = {
		...props,
		className: className ?? '' + 'animate-[spinner_1.4s_linear_infinite]',
	};

	return (
		<svg
			viewBox="0 0 66 66"
			width={width ?? 16}
			height={height ?? 16}
			stroke={fill ?? DGRAY_COLOR}
			xmlns="http://www.w3.org/2000/svg"
			{...svgProps}
		>
			<circle className="animate-[dash_1.4s_linear_infinite] origin-center" strokeDasharray={187} strokeDashoffset={0} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	
	);
};

export default Spinner