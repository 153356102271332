import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Spinner from './components/general/Loading/Spinner';
import routers from './routes';
import { useStores } from './stores';
import { ENTITY_TYPES } from './utils/constants';
import { checkHttp } from './helpers/https';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

function App() {
	const routing = useRoutes(routers);
	const { commonStore } = useStores();
	checkHttp();

	return (
		<React.Fragment>
			<React.Suspense
				fallback={
					commonStore.currentEntityType === null ? (
						<div className="w-screen h-screen flex justify-center items-center">
							<Spinner width={50} height={50} />
						</div>
					) : (
						<div className="flex justify-center items-center h-[100vh] w-full">
							{commonStore.currentEntityType === ENTITY_TYPES.DBJ ? (
								<img src="/images/dbj_logo.png" alt="DeBeers_Logo" className="h-20" />
							) : (
								<img src="/images/iod_logo.png" alt="DeBeersIoD_Logo" className="h-20" />
							)}
						</div>
					)
				}
			>
				<QueryClientProvider client={queryClient}>{routing}</QueryClientProvider>
				<ToastContainer closeOnClick={true} />
			</React.Suspense>
		</React.Fragment>
	);
}

export default App;
