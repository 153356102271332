import { makeAutoObservable } from 'mobx';
import {
	IFilterCoursesRequest,
	IGetCourseStatusResponse,
	IGetCourseTypesResponse,
} from './../../interfaces/courses/courses.interface';

export class CoursesStore {
	isFetchDataForm: boolean = false;

	courseTypes: IGetCourseTypesResponse[] = [];

	courseStatus: IGetCourseStatusResponse[] = [];

	filterOptionState: IFilterCoursesRequest = {
		courseStatus: [],
		courseTypes: [],
		createdBy: [],
		thematics: [],
		textSearch: '',
	};

	isFirstFetchingFilter: boolean = true;

	constructor() {
		makeAutoObservable(this);
	}

	async setIsFetchDataForm(isFetchDataForm: boolean) {
		return (this.isFetchDataForm = isFetchDataForm);
	}

	async setCourseTypes(courseTypes: IGetCourseTypesResponse[]) {
		return (this.courseTypes = courseTypes);
	}

	async setCourseStatus(courseStatus: IGetCourseStatusResponse[]) {
		return (this.courseStatus = courseStatus);
	}

	async setFilterOptionState(filterOptionState: IFilterCoursesRequest) {
		return (this.filterOptionState = filterOptionState);
	}

	async setIsFirstFetchingFilter(isFirstFetchingFilter: boolean) {
		return (this.isFirstFetchingFilter = isFirstFetchingFilter);
	}
}

export const coursesStore = new CoursesStore();
