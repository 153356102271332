import { IGetAdministrationAccessResponse } from '@/interfaces/users/user.interface';
import { ACCOUNTS_STATUS, ADMINISTRATION_ACCESS_TYPE } from '@/utils/constants';
import { makeAutoObservable } from 'mobx';
import { IGetCurrentUserResponse } from '../../interfaces/common/common.interface';
import { ENTITY_TYPES } from './../../utils/constants';

export class UserStore {
	currentUser: IGetCurrentUserResponse = {
		backOfficeAdministrationAccessId: '',
		backOfficeAdministrationAccessType: ADMINISTRATION_ACCESS_TYPE.FrontOfficeUser,
		companyName: '',
		countryId: '',
		email: '',
		entityId: '',
		entityType: ENTITY_TYPES.DBJ,
		firstName: '',
		hasAccessToFrontOffice: true,
		lastName: '',
		regionId: '',
		userId: '',
		workplaceId: '',
		accountStatus: ACCOUNTS_STATUS.Activated,
		availableEntities: {
			entityId: '',
			entityName: '',
			entityType: ENTITY_TYPES.DBJ,
		},
		communitiesIds: [],
		countryIds: [],
		emailReceivingSubscription: false,
		entityName: '',
		frontOfficeRoleId: '',
		isExcludedInStatistic: false,
		languageId: '',
		languageIds: [],
		modifiedBy: '',
		modifiedByName: '',
		modifiedOn: '',
		trainingFollowUps: [],
		workplaceName: '',
		lastestGMTId: '',
	};

	administrationAccess: IGetAdministrationAccessResponse[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async setCurrentUser(user: IGetCurrentUserResponse) {
		return (this.currentUser = user);
	}

	async setAdministrationAccess(administrationAccess: IGetAdministrationAccessResponse[]) {
		return (this.administrationAccess = administrationAccess);
	}
}

export const userStore = new UserStore();
