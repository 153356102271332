import { makeAutoObservable } from 'mobx';

interface IIndexSelectedArborescence {
	main: number;
	level1: number;
	level2: number;
	level3: number;
	level4: number;
}

export class ArborescenceStore {
	indexSelectedArborescence = <IIndexSelectedArborescence>{
		main: 0,
		level1: 0,
		level2: 0,
		level3: 0,
		level4: 0,
	};

	isFetchingDataForm: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	async setIndexSelectedArborescence(index: IIndexSelectedArborescence) {
		return (this.indexSelectedArborescence = index);
	}

	async setIsFetchingDataForm(isFetchingDataForm: boolean) {
		return (this.isFetchingDataForm = isFetchingDataForm);
	}

	async getIndexSelectedArborescence() {
		return this.indexSelectedArborescence;
	}
}

export const arborescenceStore = new ArborescenceStore();
