import { ICommonStatisticRequest } from '@/interfaces/statistics/statistics.interface';
import { BRAND_CODE } from '@/utils/constants';
import { makeAutoObservable } from 'mobx';
import {
	IEntityData,
	IGetBrandsResponse,
	IGetDataLanguageResponse,
	IGetRuleTypesResponse,
} from './../../interfaces/common/common.interface';
import { ENTITY_TYPES, SEARCH_ALL_VALUE } from './../../utils/constants';

export class CommonStore {
	brands: IGetBrandsResponse[] = [
		{
			brandCode: BRAND_CODE.DBFM,
			brandId: '',
			brandName: '',
			entityId: '',
			isDeleted: false,
		},
	];

	ruleTypes: IGetRuleTypesResponse[] = [];

	accessTokenSelectEntity: string = '';
	entitiesData: IEntityData[] = [];

	languages: IGetDataLanguageResponse[] = [];

	isOpenStatistic: boolean = false;

	statisticFilters: ICommonStatisticRequest = {
		from: null,
		to: null,
		communityIds: SEARCH_ALL_VALUE,
		countryIds: SEARCH_ALL_VALUE,
		dbjChannelIds: SEARCH_ALL_VALUE,
		dbfmChannelIds: SEARCH_ALL_VALUE,
		iodChannelIds: SEARCH_ALL_VALUE,
		oriChannelIds: SEARCH_ALL_VALUE,
		roleIds: SEARCH_ALL_VALUE,
		regionIds: SEARCH_ALL_VALUE,
		workplaceIds: SEARCH_ALL_VALUE,
		courseTypeIds: SEARCH_ALL_VALUE,
		courseIds: SEARCH_ALL_VALUE,
	};

	currentEntityType: ENTITY_TYPES | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	async setBrands(brands: IGetBrandsResponse[]) {
		return (this.brands = brands);
	}

	async setRules(ruleTypes: IGetRuleTypesResponse[]) {
		return (this.ruleTypes = ruleTypes);
	}

	async setAccessTokenSelectEntity(accessTokenSelectEntity: string) {
		return (this.accessTokenSelectEntity = accessTokenSelectEntity);
	}

	async setEntitiesData(entitiesData: IEntityData[]) {
		return (this.entitiesData = entitiesData);
	}

	async setLanguages(languages: IGetDataLanguageResponse[]) {
		return (this.languages = languages);
	}

	async setIsOpenStatistic(isOpen: boolean) {
		return (this.isOpenStatistic = isOpen);
	}

	async setStatisticFilters(statisticFilters: ICommonStatisticRequest) {
		return (this.statisticFilters = statisticFilters);
	}

	async setCurrentEntityType(currentEntityType: ENTITY_TYPES) {
		return (this.currentEntityType = currentEntityType);
	}
}

export const commonStore = new CommonStore();
